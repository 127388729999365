import React, { FC, ComponentProps } from 'react';
import { Link } from 'gatsby';
import freedial from '@/newcar/ui/common/img/freedial/sp_freedial2.svg';
import iconMail from '@/newcar/ui/common/img/freedial/icon-mail.svg';
import line from '@/newcar/ui/common/img/logo/line.svg';
import nurturingBannerPc from '@/newcar/ui/common/img/nurturing/to-material-pc.png';
import nurturingBannerSp from '@/newcar/ui/common/img/nurturing/to-material-sp.png';
import jpx from '@/newcar/ui/common/img/logo/logo-jpx.svg';
import pmark from '@/newcar/ui/common/img/logo/logo-pmark.svg';
import nyle from '@/newcar/ui/common/img/logo/logo-nyle.svg';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';

import * as styles from '@/newcar/ui/common/organism/Footer.module.styl';

const Footer: FC<{} & ComponentProps<'footer'>> = () => (

  <footer className="has-background-dark">
    {/* SP用 お問合せ */}
    <section className="is-hidden-desktop">
      <div className="is-padding-0">
        <div className={`has-background-primary ${styles.band}`} />
        <div className="card-content container has-text-centered">
          <h2 className="title is-3 has-text-centered has-text-white">
            お問合せ
          </h2>
          <div>
            <a href="tel:0120559442">
              <img src={freedial} alt="お問合せ先" className="is-padding-3" />
              <p className="is-size-7 has-text-white">受付時間：平日10:00〜19:00(土日・祝日・年末年始休み)</p>
            </a>
          </div>
          <div className="has-text-centered is-margin-top-6 is-margin-bottom-3 is-margin-wide-0">
            <Link
              className={`button is-large ${styles.isFixedWidthButton2}`}
              to={NEWCAR_DEFINE.PATH.CONTACT}
            >
              <i className="icon is-small">
                <img src={iconMail} alt="メールフォームで相談する" />
              </i>
              <p className="is-inline-block">メールフォームで相談する</p>
            </Link>
          </div>
          <div className="has-text-centered is-margin-bottom-3 is-margin-wide-0">
            <Link
              className={`button is-large has-text-white has-text-weight-semibold ${styles.isFixedWidthButton2} ${styles.hasBackgroundLinecolor}`}
              to={NEWCAR_DEFINE.PATH.LINE_ACCOUNT}
            >
              <i className="icon is-small">
                <img src={line} alt="LINEでチャット相談する" />
              </i>
              <p className="is-inline-block">LINEでチャット相談する</p>
            </Link>
          </div>
          <div className="has-text-centered is-margin-wide-0">
            <Link
              className={`button ${styles.isFixedWidthButtonNurturing}`}
              to={NEWCAR_DEFINE.PATH.NURTURING_LINK}
              target="_blank"
              rel="noopener"
            >
              <img className="" src={nurturingBannerSp} alt="定額カルモくんの資料ご請求はこちら！" />
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="is-padding-0">
        <div className="card-content container">
          <div className="columns is-mobile is-multiline is-margin-bottom-7">
            {/* メーカー一覧 */}
            <div className="column is-full-touch is-margin-4">
              <div className="is-size-6 has-text-white has-text-weight-semibold">
                メーカー一覧
              </div>
              <div className="columns is-mobile is-multiline is-padding-0 is-margin-0 is-margin-top-1">
                {CARMO_CONST.MAKER.FILTERED_MAKER_DATA.map((data) => (
                  <Link
                    className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                    to={NEWCAR_DEFINE.PATH.MAKER_PATH(data.id)}
                    key={data.id}
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            </div>
            {/* ボディタイプ一覧 */}
            <div className="column is-full-touch is-margin-4">
              <div className="is-size-6 has-text-white has-text-weight-semibold">
                ボディタイプ一覧
              </div>
              <div className="columns is-mobile is-multiline is-padding-0 is-margin-0 is-margin-top-1">
                {CARMO_CONST.BODY_TYPE.FILTERED_BODYTYPE_DATA.map((data) => (
                  <Link
                    className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                    to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(data.id)}
                    key={data.id}
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            </div>
            {/* その他リンク */}
            <div className={`column is-full-touch is-margin-4 ${styles.footerLinkArea} `}>
              <div className="is-size-6 has-text-weight-semibold has-text-white">
                リンク
              </div>
              <div className="columns is-mobile is-multiline is-padding-0 is-margin-0 is-margin-top-1">
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.TOP}
                >
                  TOP
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.ABOUT_CARMO}
                >
                  定額カルモくんとは？
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.CONTRACT_FLOW}
                >
                  ご納車までの流れ
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.MAINTENANCE}
                >
                  メンテナンスプラン
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.FEE}
                >
                  値段・料金について
                </Link>
                <a
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  href={NEWCAR_DEFINE.PATH.MERIT_DEMERIT}
                >
                  カーリースのメリット・デメリット
                </a>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.SHOPLIST}
                >
                  加盟店舗一覧
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.INFORMATION}
                >
                  お知らせ一覧
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.FAQ}
                >
                  よくあるご質問
                </Link>
                <a
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  href={NEWCAR_DEFINE.PATH.COMPANY_ABOUT}
                >
                  企業情報
                </a>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.CONTACT}
                >
                  お客様お問合せ
                </Link>
                <a
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  href={NEWCAR_DEFINE.PATH.CORPORATE_GOOGLE_FORM}
                >
                  法人企業様お問合せ
                </a>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.LAW}
                >
                  特定商取引法に基づく表記
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.INSURANCESALESPOLICY}
                >
                  取扱い保険会社／推奨販売に関する方針
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.SOLICITATIOONPOLICY}
                >
                  損害保険代理店等における勧誘方針
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.TERMS}
                >
                  利用規約
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.CREDIT_INFO_CORRECTION}
                >
                  信用情報の訂正および利用停止の申し出手続き
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.PRIVACY}
                >
                  プライバシーポリシー
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.CONTENTS_POLICY}
                >
                  コンテンツポリシー
                </Link>
                <Link
                  className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                  to={NEWCAR_DEFINE.PATH.CITATION_REPRINTING_RULES}
                >
                  引用・転載について
                </Link>
              </div>
            </div>
            <div className="column is-margin-5s is-hidden-touch is-narrow">
              {/* PC用お問い合わせ */}
              <p className="is-size-4 is-margin-top-3">
                <strong className="has-text-white">ご不明な点はお気軽にご相談ください</strong>
              </p>
              <div>
                <a href="tel:0120559442">
                  <img src={freedial} alt="お問合せ先" className="" />
                  <p className="is-size-7 has-text-white is-margin-left-4 is-margin-top-3">受付時間：平日10:00〜19:00(土日・祝日・年末年始休み)</p>
                </a>
              </div>
              <div className="has-text-centered is-margin-4 is-margin-wide-0">
                <Link
                  className={`button is-large ${styles.isFixedWidthButton2}`}
                  to={NEWCAR_DEFINE.PATH.CONTACT}
                >
                  <i className="icon is-small">
                    <img src={iconMail} alt="メールフォームで相談する" />
                  </i>
                  <p className="is-inline-block">メールフォームで相談する</p>
                </Link>
              </div>
              <div className="has-text-centered is-margin-4 is-margin-wide-0">
                <Link
                  className={`button is-large has-text-white has-text-weight-semibold ${styles.isFixedWidthButton2} ${styles.hasBackgroundLinecolor}`}
                  to={NEWCAR_DEFINE.PATH.LINE_ACCOUNT}
                >
                  <i className="icon is-small">
                    <img src={line} alt="LINEでチャット相談する" />
                  </i>
                  <p className="is-inline-block">LINEでチャット相談する</p>
                </Link>
              </div>
              <div className="has-text-centered is-margin-4 is-margin-wide-0">
                <Link
                  className={`button ${styles.isFixedWidthButtonNurturing}`}
                  to={NEWCAR_DEFINE.PATH.NURTURING_LINK}
                  target="_blank"
                  rel="noopener"
                >
                  <img className="" src={nurturingBannerPc} alt="定額カルモくんの資料ご請求はこちら！" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="has-background-white has-text-centered is-margin-bottom-6">
      <div className={`columns is-mobile ${styles.footerLogoArea} `}>
        {/* ナイルロゴ */}
        <div className="column has-text-left">
          <a href="https://nyle.co.jp/" target="_blank" rel="noreferrer">
            <img loading="lazy" src={nyle} alt="ナイル株式会社" width="168" height="33" />
          </a>
        </div>
        {/* 東証・Pマークロゴ */}
        <div className="column has-text-right">
          <img loading="lazy" className="is-margin-right-3" src={jpx} alt="東証ロゴマーク" width="46" height="51" />
          <a href="https://privacymark.jp/" target="_blank" rel="noreferrer">
            <img loading="lazy" src={pmark} alt="プライバシーマーク" width="52" height="52" />
          </a>
        </div>
      </div>
      {/* ロゴ */}
      {/* 著作権表示 */}
      <small className="is-size-6 has-text-dark">
        {CARMO_CONST.copyright}
      </small>
    </section>
  </footer>
);

export default Footer;
